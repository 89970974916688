function convertToCountdown(difference) {
	
	if (difference < 0) {
		return {
			days:  '00',
			hours: '00',
			mins:  '00',
			sec:   '00',
			isDone: true,
		};
	}
	
	const MILISEC  = 1000;
	const hoursDay = 24;
	const secMin   = 60;

	let days  = Math.floor(difference / (MILISEC * secMin * secMin * hoursDay));
	let hours = Math.floor((difference % (MILISEC * secMin * secMin * hoursDay)) / (MILISEC * secMin * secMin));
	let mins  = Math.floor((difference % (MILISEC * secMin * secMin)) / (MILISEC * secMin));
	let sec   = Math.floor((difference % (MILISEC * secMin)) / MILISEC);

	days  = days  < 10 ? '0' + days : days;
	hours = hours < 10 ? '0' + hours : hours;
	mins  = mins  < 10 ? '0' + mins : mins;
	sec   = sec   < 10 ? '0' + sec : sec;
	
	return {
		days, hours, mins, sec, isDone: false,
	};
}

function getCountdownTillMidnight(date) {
	const midnight = date ? new Date(date) : new Date();
	const now      = new Date();
	midnight.setHours(24, 0, 0, 0);
	const countdown = convertToCountdown(midnight.getTime() - now.getTime());
	return countdown;
}


function getCountdownTillDate(date, addMinute = false) {
	const now         = new Date();
	const endDate     = new Date(date);
	const endDateTime = endDate.getTime() + (addMinute ? 60000 : 0);
	const countdown   = convertToCountdown(endDateTime - now.getTime());
	return countdown;
}

export {
	getCountdownTillMidnight,
	getCountdownTillDate,
	convertToCountdown,
};